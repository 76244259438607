import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import React from 'react'
import Layout from "../components/Layout"
import Sidebar from "../components/Sidebar"
import Newsletter from '../components/Newsletter'

export default function read({ data }) {
    return (
      <Layout>
        <Helmet>
          <title>Reading List | {data.site.siteMetadata.title}</title>

          <meta name="description" content={data.site.siteMetadata.description} />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:description" content={data.site.siteMetadata.description} />
          <meta property="og:image" content={data.site.siteMetadata.ogimg} />
          <meta property="og:image:alt" content="Use Less Divs" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={data.site.siteMetadata.url} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@JocoPolgar" />
          <meta name="twitter:creator" content="@JocoPolgar" />
          <meta name="twitter:url" content={data.site.siteMetadata.url} />
          <meta name="twitter:title" content={data.site.siteMetadata.title} />
          <meta name="twitter:description" content={data.site.siteMetadata.description} />
          <meta name="twitter:image" content={data.site.siteMetadata.ogimg} />
          <link rel="canonical" href={data.site.siteMetadata.url} />
        </Helmet>
        <main className="reading-list">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h1>Reading list</h1>
                <p>These are articles and pages, I found interesting.</p>
                <section className="reading">
                  <h2 className="h2">September 2021</h2>
                  <ul>
                    <li>
                      <a className="reading__link" href="https://webaim.org/projects/screenreadersurvey9/" rel="noreferrer" target="_blank">Screen Reader User Survey #9 Results <span className="reading__author">- WebAIM</span></a>
                    </li>
                    <li>
                      <a className="reading__link" href="https://www.smashingmagazine.com/2020/09/design-reduced-motion-sensitivities//" rel="noreferrer" target="_blank">Designing With Reduced Motion For Motion Sensitivities <span className="reading__author">- Val Head</span></a>
                    </li>
                    <li>
                      <a className="reading__link" href="https://www.ab11y.com/articles/ux-principles-that-include-cognitive-accessibility/ " rel="noreferrer" target="_blank">UX Principles that include Cognitive Accessibility <span className="reading__author">- Gareth Ford Williams</span></a>
                    </li>
                    <li>
                      <a className="reading__link" href="https://www.bram.us/2021/07/08/the-large-small-and-dynamic-viewports/" rel="noreferrer" target="_blank">The Large, Small, and Dynamic Viewports <span className="reading__author">- Bramus Van Damme</span></a>
                    </li>
                    <li>
                      <a className="reading__link" href="https://www.matuzo.at/blog/element-diversity/" rel="noreferrer" target="_blank">Element diversity <span className="reading__author">- Manuel Matuzović</span></a>
                    </li>
                    <li>
                      <a className="reading__link" href="https://benmyers.dev/blog/css-can-influence-screenreaders/" rel="noreferrer" target="_blank">CSS Can Influence Screenreaders <span className="reading__author">- Ben Myers</span></a>
                    </li>
                    <li>
                      <a className="reading__link" href="https://alvaromontoro.com/blog/67979/creating-accessible-css-art" rel="noreferrer" target="_blank">Creating Accessible CSS Art <span className="reading__author">- Álvaro Montoro</span></a>
                    </li>
                  </ul>
                </section>
                <section className="reading">
                  <h2 className="h2">August 2021</h2>
                  <ul>
                    <li>
                      <a className="reading__link" href="https://a11y.coffee/" rel="noreferrer" target="_blank">A11y Coffee <span className="reading__author">- Amberley</span></a>
                    </li>
                  </ul>
                </section>
                <section className="reading">
                  <h2 className="h2">July 2021</h2>
                  <ul>
                    <li>
                      <a className="reading__link" href="https://www.joshwcomeau.com/performance/embracing-modern-image-formats/" rel="noreferrer" target="_blank">Embracing modern image formats <span className="reading__author">- Josh W. Comeau</span></a>
                    </li>
                  </ul>
                </section>
                <Newsletter />
               </div>
            <Sidebar />
          </div>
        </div>
      </main>
    </Layout>
    )
}

export const query = graphql`
query {
  site {
    siteMetadata {
      description
      ogimg
      title
      url
    }
  }
}
`